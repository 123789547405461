export default {
    namespaced: true,
    state: {
        id: null,
        name: null,
        username: null,
        profilePicture: null,
        typeId: null,
        avalBalance: null,
        accountccy: null,
        perChannel: [],
        isVendor: false,
        vendorKey: null,
        vendorSecret: null,
        vendorBetWebhook: null,
        channels: [],
        searchUser: false,
        searchState: false,
    },
    getters: {},
    mutations: {
        UPDATE_PROFILE(state, val) {
            state.id = val.id;
            state.name = val.name;
            state.username = val.username;
            state.profilePicture = val.profilePicture;
            state.typeId = val.typeId;
            state.accountccy = val.account?.ccy;
            state.avalBalance = val.account?.avalBalance;
            state.perChannel = val.perChannel;
            state.isVendor = val.isVendor;
            state.vendorKey = val.vendorKey;
            state.vendorSecret = val.vendorSecret;
            state.vendorBetWebhook = val.vendorBetWebhook;
            state.channels = val.channels;
            state.searchUser = val.searchUser;
            state.searchState = val.searchState;
        },
    },
    actions: {},
}
